import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/ijuP_yicTGA">
    <SEO title="Cross Church Unplugged - Holiday on its Head" />
  </Layout>
)

export default SermonPost
